import React from 'react'
import styled from 'styled-components'
import Layout from 'components/layout'
import Content from 'components/new/content'
import HeaderSpacer from 'components/layout/header/spacer'
import { graphql, Link } from 'gatsby'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import P from '~/components/new/typography/p'
import { getSrc, GatsbyImage } from 'gatsby-plugin-image'
import { Seo } from '~/components/seo'
import { usePreferredLocation } from 'hooks/use-preferred-location'
import { BreadcrumbsNav } from '~/components/new/breadcrumbs/breadcrumbs-nav'
import Spacer from '~/components/new/spacer'
import Button from 'components/button/button'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { H2, H3, H4, H5 } from '~/components/new/typography'
import { useQuery } from '@tanstack/react-query'
import { fetchAllJobRequisitions } from 'utils/sanity/job-requisitions'
import { JobRequisitionCard } from '~/components/new/cards/job-requisition-card'
import { SeeMoreJobsCard } from '~/components/new/cards/see-more-jobs-card'
import days from 'utils/constants/days'
import months from 'utils/constants/months'
import getDaysRangeFromToday from 'utils/dates/get-days-range-from-today'
import parseLocationHours from 'utils/location-hours/parse-location-hours'
import isSameDay from 'utils/dates/is-same-day'
import { Carousel } from '~/components/new/carousel'
import AdditionalResources from 'components/new/additional-resources'
import toTitleCase from 'utils/to-title-case'

// BEGIN - Temporary Mayfield opening notification
import { IoMdAlert } from '@react-icons/all-files/io/IoMdAlert'

const Notification = props => {
  const { locationNumber } = props

  if (locationNumber === 20) {
    return (
      <NotificationContainer>
        <div className='icon-container'>
          <IoMdAlert aria-hidden='true' focusable='false' role='presentation' />
        </div>
        <p>
          Our <Link to='/locations/mayfield/'>Mayfield, KY store</Link> is now open!
        </p>
      </NotificationContainer>
    )
  }

  return null
}

const NotificationContainer = styled.div`
  align-items: stretch;
  background-color: ${props => props.theme.color.y50};
  display: flex;
  margin-bottom: ${props => props.theme.size.m};

  .icon-container {
    align-items: center;
    background-color: ${props => props.theme.color.y300};
    display: flex;
    padding: ${props => props.theme.size.xs};
  }

  svg {
    color: ${props => props.theme.color.y700};
    font-size: 21px;
  }

  p {
    color: ${props => props.theme.color.y700};
    margin: 0;
    padding: ${props => props.theme.size.s};
  }

  @media (max-width: 599px) {
    svg {
      font-size: 18px;
    }

    p {
      font-size: 0.95em;
    }
  }
`
// END - Temporary Mayfield opening notification

const formatStoreHours = ({ hours, specialHours = [], status }) => {
  const currentDate = new Date()
  const currentDate12AM = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  )
  const firstDayOfCurrentMonth = new Date(new Date(currentDate12AM).setDate(1))

  // Within the next 2 months
  const specialHoursCutoffDate = new Date(
    new Date(firstDayOfCurrentMonth).setMonth(firstDayOfCurrentMonth.getMonth() + 2)
  )

  /**
   * Special store hours coming up within the next 2 months, including today
   * @type {{date: Date, dateString: string, hours: string}[]}
   */
  const upcomingSpecialHours = specialHours
    .map(obj => {
      const [objDateYear, objDateMonth, objDateDay] = obj.date.split('-')
      const date = new Date(objDateYear, Number(objDateMonth) - 1, objDateDay)
      return {
        ...obj,
        dateString: `${days[date.getUTCDay()]}, ${months[date.getUTCMonth()]} ${date.getUTCDate()}`,
        date,
      }
    })
    .filter(obj => currentDate12AM <= obj.date && obj.date < specialHoursCutoffDate)

  /**
   * This week's store hours, sorted starting from today and including changes from special hours
   * @type {{closed: boolean, date: Date, day: string, end: string, hours: string, isSpecialHours: boolean, specialHoursTitle: string, start: string}[]}
   */
  const thisWeekStoreHours = getDaysRangeFromToday(true).map((dayNumber, index) => {
    const date = new Date(currentDate12AM.getTime() + 1000 * 60 * 60 * 24 * index)

    const day = days[dayNumber]

    const specialHoursMatch = upcomingSpecialHours.find(obj => isSameDay(obj.date, date))

    const storeHours = specialHoursMatch ? specialHoursMatch.hours : hours[day]

    const isSpecialHours = Boolean(specialHoursMatch)

    const specialHoursTitle = specialHoursMatch ? specialHoursMatch.title : null

    const hoursParsed = parseLocationHours(storeHours)

    return {
      ...hoursParsed,
      date,
      day,
      hours: storeHours,
      isSpecialHours,
      specialHoursTitle,
      upcomingSpecialHours,
    }
  })

  /**
   * Today store hours details
   * @type {{date: Date, day: string, hours: string, isSpecialHours: boolean, specialHoursTitle: string}}
   */
  const todayStoreHours = thisWeekStoreHours[0]

  /**
   * @type {boolean}
   */
  const isCurrentlyOpen =
    status === 'open' &&
    todayStoreHours.closed !== true &&
    new Date(
      todayStoreHours.date.getFullYear(),
      todayStoreHours.date.getMonth(),
      todayStoreHours.date.getDate(),
      todayStoreHours.start.slice(0, 2),
      todayStoreHours.start.slice(3)
    ) < currentDate &&
    currentDate <
      new Date(
        todayStoreHours.date.getFullYear(),
        todayStoreHours.date.getMonth(),
        todayStoreHours.date.getDate(),
        todayStoreHours.end.slice(0, 2),
        todayStoreHours.end.slice(3)
      )

  /**
   * @type {('Temporarily Closed'|'Open'|'Closed')}
   */
  const currentStatus =
    status === 'temporarily-closed' ? 'Temporarily Closed' : isCurrentlyOpen ? 'Open' : 'Closed'

  return {
    currentStatus,
    isCurrentlyOpen,
    thisWeekStoreHours,
    todayStoreHours,
    upcomingSpecialHours,
  }
}

/**
 * @param {import('gatsby').PageProps<LocationTemplateDataProps>} props
 */
const LocationTemplate = props => {
  const {
    data: {
      sanityLocation: {
        address,
        city,
        descriptionHtml,
        directionsLink,
        geolocation,
        hours,
        images,
        locationNumber,
        partsPhoneNumber,
        phoneNumber,
        retailLocation,
        servicePhoneNumber,
        showPartsPhoneNumber,
        showServicePhoneNumber,
        slug,
        specialHours,
        state,
        status,
        title,
        zip,
      },
      salesDepartmentImage,
      serviceDepartmentImage,
      partsDepartmentImage,
      precisionAgDepartmentImage,
    },
  } = props

  // Meta description
  const metaDescription = `Browse our wide selection of new and used equipment, shop parts for your machine, or schedule a service at Hutson, Inc. in ${title}.`

  // Images
  const primaryImage = images[0]
  const carouselImages = images.slice(1)
  const metaImage = getSrc(primaryImage.asset.gatsbyImageData)

  // User preferred location
  const { preferredLocation, setPreferredLocation } = usePreferredLocation()

  const isPreferredLocation =
    preferredLocation && preferredLocation.locationNumber === locationNumber

  // Store hours
  const {
    currentStatus,
    isCurrentlyOpen,
    thisWeekStoreHours,
    todayStoreHours,
    upcomingSpecialHours,
  } = formatStoreHours({
    hours,
    specialHours,
    status,
  })

  // After hours contact info
  const showAfterHoursPartsPhoneNumber = showPartsPhoneNumber && partsPhoneNumber
  const showAfterHoursServicePhoneNumber = showServicePhoneNumber && servicePhoneNumber

  const sameAfterHoursPhoneNumber =
    showAfterHoursPartsPhoneNumber &&
    showAfterHoursServicePhoneNumber &&
    partsPhoneNumber === servicePhoneNumber

  const showAfterHoursPhoneNumbers =
    !isCurrentlyOpen && (showAfterHoursPartsPhoneNumber || showAfterHoursServicePhoneNumber)

  // Breadcrumbs
  const breadcrumbs = [
    {
      name: 'Hutson Inc',
      displayName: 'Home',
      link: '/',
    },
    {
      name: 'Locations',
      link: '/locations/',
    },
    {
      name: title,
      link: slug,
    },
  ]

  return (
    <React.Fragment>
      <Layout>
        <Seo
          titleOverride={`Hutson, Inc. in ${title} | John Deere Dealer | Equipment, Parts & Service`}
          description={metaDescription}
          breadcrumbs={breadcrumbs}
          image={metaImage}
          ogType='place'
        >
          <meta property='place:location:latitude' content={geolocation.lat} />
          <meta property='place:location:longitude' content={geolocation.lng} />
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'LocalBusiness',
              'description': metaDescription,
              'name': `Hutson, Inc. in ${title}`,
              'telephone': `+1-${phoneNumber}`,
              'contactPoint': [
                {
                  '@type': 'ContactPoint',
                  'telephone': `+1-${phoneNumber}`,
                  'contactType': 'customer service',
                  'name': 'Front Desk',
                },
                ...(showAfterHoursPartsPhoneNumber
                  ? [
                      {
                        '@type': 'ContactPoint',
                        'telephone': `+1-${partsPhoneNumber}`,
                        'contactType': 'customer service',
                        'name': 'After Hours Parts',
                      },
                    ]
                  : []),
                ...(showAfterHoursServicePhoneNumber
                  ? [
                      {
                        '@type': 'ContactPoint',
                        'telephone': `+1-${servicePhoneNumber}`,
                        'contactType': 'customer service',
                        'name': 'After Hours Service',
                      },
                    ]
                  : []),
              ],
              'address': {
                '@type': 'PostalAddress',
                'addressCountry': 'USA',
                'addressLocality': city,
                'addressRegion': state,
                'postalCode': zip,
                'streetAddress': address,
              },
              'geo': {
                '@type': 'GeoCoordinates',
                'latitude': geolocation.lat,
                'longitude': geolocation.lng,
              },
              'image': {
                '@context': 'http://schema.org',
                '@type': 'ImageObject',
                'url': getSrc(primaryImage.asset.gatsbyImageData),
                'description': `Photo of the Hutson, Inc. location in ${title}`,
                'name': `Hutson, Inc. in ${title}`,
              },
              ...(currentStatus === 'Temporarily Closed'
                ? {}
                : {
                    openingHoursSpecification: thisWeekStoreHours.reduce((acc, obj) => {
                      if (!obj.closed) {
                        acc.push({
                          '@type': 'OpeningHoursSpecification',
                          'dayOfWeek': toTitleCase(obj.day),
                          'opens': obj.start,
                          'closes': obj.end,
                        })
                      }
                      return acc
                    }, []),
                  }),
              'priceRange': '$-$$$$',
            })}
          </script>
        </Seo>
        <PageHero>
          <PageHeroImageColumn>
            <GatsbyImage
              image={primaryImage.asset.gatsbyImageData}
              alt=''
              style={{ width: '100%', height: '100%', position: 'absolute' }}
              objectFit='cover'
              objectPosition='50% 50%'
            />
          </PageHeroImageColumn>
          <PageHeroContentColumn>
            <HeaderSpacer />
            <PageHeroContent>
              <Spacer size='m' type='padding' variable />
              <BreadcrumbsNav items={breadcrumbs} />
              <h1>{title}</h1>
              <PageHeroContentAddress>
                <OutboundLink href={directionsLink}>
                  {address}
                  <br />
                  {city}, {state} {zip}
                </OutboundLink>
              </PageHeroContentAddress>
              <PageHeroContentPhone>
                Phone:{' '}
                <OutboundLink
                  href={`tel:${phoneNumber}`}
                  className='page-hero-grid-content-phone-number'
                >
                  {phoneNumber}
                </OutboundLink>
              </PageHeroContentPhone>
              <hr />
              <PageHeroContentHours>
                <span data-status={isCurrentlyOpen ? 'open' : 'closed'}>{currentStatus}</span>
                {isCurrentlyOpen ? ` ${todayStoreHours.hours}` : null}
                {todayStoreHours.isSpecialHours ? (
                  <PageHeroContentSpecialHoursTitle>
                    {' '}
                    ({todayStoreHours.specialHoursTitle})
                  </PageHeroContentSpecialHoursTitle>
                ) : null}
              </PageHeroContentHours>
              <PageHeroContentCTAs>
                <Button
                  onClick={() => setPreferredLocation(locationNumber)}
                  disabled={isPreferredLocation}
                  color='yellow'
                  size='small'
                >
                  {isPreferredLocation ? 'My Store' : 'Set as My Store'}
                </Button>
                <Button as={OutboundLink} href={directionsLink} color='green' size='small' ghost>
                  Get Directions
                </Button>
              </PageHeroContentCTAs>
            </PageHeroContent>
          </PageHeroContentColumn>
        </PageHero>
        <Section>
          <Content kind='full'>
            <Notification locationNumber={locationNumber} />
            <MapHoursSection>
              <MapColumn>
                <iframe
                  loading='lazy'
                  allowFullScreen
                  title={`Map showing where Hutson, Inc. in ${title} is located`}
                  src={`https://www.google.com/maps/embed/v1/place?q=Hutson+Inc+${city}+${state}&key=${process.env.GATSBY_GOOGLE_API_KEY}`}
                ></iframe>
              </MapColumn>
              {currentStatus === 'Temporarily Closed' ? null : (
                <HoursColumn>
                  <H4 as='h2'>Store Hours</H4>
                  <table>
                    <tbody>
                      {thisWeekStoreHours.map(obj => (
                        <tr key={obj.day}>
                          <td>{obj.day}</td>
                          <td>{obj.hours}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {upcomingSpecialHours.length > 0 ? (
                    <React.Fragment>
                      <hr />
                      <H5 as='h3'>Special Store Hours</H5>
                      <table>
                        <tbody>
                          {upcomingSpecialHours.map(obj => (
                            <tr key={obj.dateString}>
                              <td>{obj.dateString}</td>
                              <td>{obj.hours}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </React.Fragment>
                  ) : null}
                  {showAfterHoursPhoneNumbers ? (
                    <AfterHoursSupportSection>
                      <hr />
                      <H5 as='h2'>After Hours Support</H5>
                      <p>Call or text us:</p>
                      <table>
                        <tbody>
                          {sameAfterHoursPhoneNumber ? (
                            <tr>
                              <td>Parts & Service</td>
                              <td>
                                <OutboundLink href={`tel:${partsPhoneNumber}`}>
                                  {partsPhoneNumber}
                                </OutboundLink>
                              </td>
                            </tr>
                          ) : (
                            <React.Fragment>
                              {showServicePhoneNumber ? (
                                <tr>
                                  <td>Service</td>
                                  <td>
                                    <OutboundLink href={`tel:${servicePhoneNumber}`}>
                                      {servicePhoneNumber}
                                    </OutboundLink>
                                  </td>
                                </tr>
                              ) : null}
                              {showPartsPhoneNumber ? (
                                <tr>
                                  <td>Parts</td>
                                  <td>
                                    <OutboundLink href={`tel:${partsPhoneNumber}`}>
                                      {partsPhoneNumber}
                                    </OutboundLink>
                                  </td>
                                </tr>
                              ) : null}
                            </React.Fragment>
                          )}
                        </tbody>
                      </table>
                    </AfterHoursSupportSection>
                  ) : null}
                </HoursColumn>
              )}
            </MapHoursSection>
            <Spacer />
            <H2>Departments</H2>
            <Spacer size='m' />
            <DepartmentsSection>
              <DepartmentCard
                title='Sales'
                copy='Browse our equipment lineup, request a quote, and apply for financing'
                image={salesDepartmentImage}
                link='/equipment/'
              />
              <DepartmentCard
                title='Service'
                copy='Learn more about our service work and schedule a service online'
                image={serviceDepartmentImage}
                link={retailLocation ? `${slug}service/` : '/service/'}
              />
              <DepartmentCard
                title='Parts'
                copy='See our parts offerings and order parts online'
                image={partsDepartmentImage}
                link='/parts/'
              />
              <DepartmentCard
                title='Precision Ag'
                copy='Maximize your profitability with our precision and agronomic services'
                image={precisionAgDepartmentImage}
                link='/precision-ag/'
              />
            </DepartmentsSection>
          </Content>
        </Section>

        <CareersSection
          locationNumber={locationNumber}
          queryTitle={`${city}, ${state}`}
          title={title}
        />

        {descriptionHtml || carouselImages.length > 0 ? (
          <Section>
            <Content kind='full'>
              {descriptionHtml ? (
                <React.Fragment>
                  <H2>About Hutson, Inc. in {title}</H2>
                  <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
                </React.Fragment>
              ) : null}
              {carouselImages.length > 0 ? (
                <ImageCarousel breakPoints={[{ width: 1, itemsToShow: 1, itemsToScroll: 1 }]}>
                  {carouselImages.map((image, index) => (
                    <GatsbyImage image={image.asset.gatsbyImageData} alt='' key={index} />
                  ))}
                </ImageCarousel>
              ) : null}
            </Content>
          </Section>
        ) : null}
        <Section>
          <AdditionalResourcesSection
            resources={[
              {
                title: 'Browse Equipment',
                links: [
                  <Link to='/lawn-garden/'>John Deere Lawn & Garden Equipment</Link>,
                  <Link to='/agriculture/'>John Deere Ag Equipment</Link>,
                  <Link to='/construction/'>John Deere Compact Construction Equipment</Link>,
                  <Link to={retailLocation ? `${slug}stihl/` : '/stihl/'}>STIHL Equipment</Link>,
                ],
              },
            ]}
          />
        </Section>
      </Layout>
    </React.Fragment>
  )
}

const Section = styled.div`
  :nth-child(odd) {
    background-color: ${props => props.theme.color.n10};
  }
`

const PageHero = styled.div`
  background-color: ${props => props.theme.color.n10};

  @media (min-width: 900px) {
    ${clearfix}
    display: flex;
    flex-direction: row-reverse;
    min-height: 400px;
  }

  @media (min-width: 1024px) {
    min-height: 500px;
  }
`

const PageHeroImageColumn = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  width: auto;

  @media (min-width: 900px) {
    height: auto;
    overflow: auto;
    padding-bottom: 0;
    ${column('5/12', 0)}
  }

  @media (min-width: 1024px) {
    ${column('7/12', 0)}
  }
`

const PageHeroContentColumn = styled.div`
  @media (min-width: 900px) {
    ${column('7/12', 0)}
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    ${column('5/12', 0)}
  }
`

const PageHeroContent = styled.div`
  padding: 0 24px 36px;
  width: 100%;

  h1 {
    color: ${props => props.theme.color.n900};
    font-size: 36px;
    font-weight: 600;
    padding: 0;
    margin: 24px 0 0;

    @media (min-width: 900px) {
      margin: 36px 0 0;
    }
  }

  hr {
    background-color: ${props => props.theme.color.n50};
    border: 0;
    height: 1px;
    margin: 16px 0;
    width: 100%;
  }

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 0 64px 36px;
  }
`

const PageHeroContentAddress = styled(P)`
  font-size: 16.8px;
  line-height: 1.3;
  margin: 4px 0 0;

  a {
    color: ${props => props.theme.color.n900};
    text-decoration: none;

    :hover,
    :active {
      text-decoration: underline;
    }
  }
`

const PageHeroContentPhone = styled(P)`
  color: ${props => props.theme.color.n900};
  font-size: 16.8px;
  margin: 0;

  a {
    color: ${props => props.theme.color.n900};
    text-decoration: none;

    :hover,
    :active {
      text-decoration: underline;
    }
  }
`

const PageHeroContentHours = styled.div`
  color: ${props => props.theme.color.n400};
  font-size: 16.8px;
  font-weight: 600;
  margin: 0 0 24px;

  span[data-status='open'] {
    color: ${props => props.theme.color.g400};
  }
  span[data-status='closed'] {
    color: ${props => props.theme.color.r500};
  }
`

const PageHeroContentSpecialHoursTitle = styled.span`
  font-weight: 400;
`

const PageHeroContentCTAs = styled.div`
  button,
  a {
    display: inline-block;
    margin-bottom: 8px;
    text-decoration: none;

    :not(:last-child) {
      margin-right: 8px;
    }
  }
`

const MapHoursSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: flex-start;
  }
`

const MapColumn = styled.div`
  background-color: ${props => props.theme.color.n10};

  iframe {
    border: 0;
    height: 400px;
    max-height: 50vh;
    width: 100%;
  }

  @media (min-width: 900px) {
    height: 0;
    flex-grow: 2;
    overflow: hidden;
    margin-right: 24px;
    padding-bottom: 43.75%;
    position: relative;
    width: auto;

    iframe {
      height: 100%;
      max-height: none;
      position: absolute;
    }
  }
`

const HoursColumn = styled.div`
  background-color: ${props => props.theme.color.n20};
  margin-bottom: 24px;
  padding: 24px;

  h2 {
    font-weight: 700;
  }

  hr {
    background-color: ${props => props.theme.color.n50};
    border: 0;
    height: 1px;
    margin: 16px 0;
    width: 100%;
  }

  table {
    border: none;
    border-collapse: collapse;
    line-height: 1.3;
    margin: 8px 0 0;
    width: 100%;

    td:first-child {
      text-transform: capitalize;
    }

    td:last-child {
      text-align: right;
    }
  }

  @media (min-width: 900px) {
    margin-bottom: 0;
    min-width: 300px;
  }
`

const AfterHoursSupportSection = styled.div`
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    margin: 8px 0 4px;
  }

  table {
    margin: 0;
  }

  a {
    color: ${props => props.theme.color.g400};
    text-decoration: underline;

    :hover,
    :focus {
      text-decoration: none;
    }
  }
`

const DepartmentsSection = styled.div`
  @media (min-width: 600px) {
    ${clearfix}
    display: flex;
    flex-wrap: wrap;
  }
`

const HiddenLink = ({ to }) => {
  return (
    <Link
      to={to}
      aria-hidden='true'
      tabIndex='-1'
      style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
    />
  )
}

const DepartmentCard = ({ copy, image, link, title }) => {
  return (
    <DepartmentCardContainer>
      <DepartmentCardImageContainer>
        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt='' />
        <HiddenLink to={link} />
      </DepartmentCardImageContainer>
      <DepartmentCardContent>
        <H3>
          <HiddenLink to={link} />
          <span>{title}</span>
        </H3>
        <P>{copy}</P>
        <Spacer size='s' />
        <Button
          as={DepartmentCardButtonLink}
          to={link}
          ghost
          color='green'
          aria-label={`Learn more about the ${title} department`}
        >
          Learn more
        </Button>
      </DepartmentCardContent>
    </DepartmentCardContainer>
  )
}

const DepartmentCardContainer = styled.div`
  background-color: ${props => props.theme.color.n20};

  @media (min-width: 600px) {
    ${column('1/2', 16)}
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 900px) {
    :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (min-width: 1000px) {
    ${column('1/4', 16)}
  }
`

const DepartmentCardImageContainer = styled.div`
  flex-shrink: 0;
  position: relative;
`

const DepartmentCardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  align-items: flex-start;
  padding: 24px;

  h3 {
    position: relative;

    a:hover,
    a:focus {
      + span {
        text-decoration: underline;
      }
    }
  }

  p {
    flex-grow: 2;
  }
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  width: auto;
`

const DepartmentCardButtonLink = styled(ButtonLink)`
  justify-self: flex-end;
`

const CareersSection = props => {
  const { locationNumber, queryTitle, title } = props
  /**
   * @type {import('@tanstack/react-query').UseQueryResult<Array<import('utils/sanity/job-requisitions').JobRequisitionCardData>>}
   */
  const JobRequisitionsQueryResponse = useQuery({
    queryKey: ['job-requisitions', { limit: 3, locationNumber }],
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryFn: async () => {
      /**
       * @type {Array<import('utils/sanity/job-requisitions').JobRequisitionCardData>}
       */
      const response = await fetchAllJobRequisitions({ limit: 3, locationNumber })

      return response
    },
    keepPreviousData: true,
  })

  return JobRequisitionsQueryResponse.isFetched &&
    Array.isArray(JobRequisitionsQueryResponse.data) &&
    JobRequisitionsQueryResponse.data.length > 0 ? (
    <Section>
      <Content kind='full'>
        <H2>Careers at Hutson, Inc. in {title}</H2>
        <Spacer size='m' />
        <JobRequisitionsGrid>
          {JobRequisitionsQueryResponse.data.map(jobRequisition => (
            <JobRequisitionCard
              key={jobRequisition.key}
              careerAreas={jobRequisition.careerAreas}
              locationsText={jobRequisition.locationsText}
              publishedDate={jobRequisition.publishedDate}
              slug={jobRequisition.slug}
              title={jobRequisition.title}
              type={jobRequisition.type}
            />
          ))}
          <SeeMoreJobsCard
            slug={`/careers/jobs/?Location=${encodeURIComponent(queryTitle)}`}
            title={`Browse all open positions in ${title}`}
          />
        </JobRequisitionsGrid>
      </Content>
    </Section>
  ) : null
}

const JobRequisitionsGrid = styled.div`
  > * {
    margin-bottom: 16px;
  }

  @media (min-width: 600px) {
    ${clearfix};
    display: flex;
    flex-wrap: wrap;

    > * {
      ${column('1/2', 16)};
    }
  }

  @media (min-width: 1000px) {
    > * {
      ${column('1/4', 16)};
      margin-bottom: 0;
    }
  }
`

const ImageCarousel = styled(Carousel)`
  :not(:first-child) {
    margin-top: 48px;
  }
`

const AdditionalResourcesSection = styled(AdditionalResources)`
  background-color: transparent;
`

/**
 * @typedef LocationTemplateDataProps
 * @property {Object} sanityLocation
 * @property {string} sanityLocation.address
 * @property {string} sanityLocation.city
 * @property {string} sanityLocation.descriptionHtml
 * @property {string} sanityLocation.directionsLink
 * @property {Object} sanityLocation.geolocation
 * @property {number} sanityLocation.geolocation.lat
 * @property {number} sanityLocation.geolocation.lng
 * @property {Object} sanityLocation.hours
 * @property {string} sanityLocation.hours.sunday
 * @property {string} sanityLocation.hours.monday
 * @property {string} sanityLocation.hours.tuesday
 * @property {string} sanityLocation.hours.wednesday
 * @property {string} sanityLocation.hours.thursday
 * @property {string} sanityLocation.hours.friday
 * @property {string} sanityLocation.hours.saturday
 * @property {Object[]} sanityLocation.images
 * @property {import('gatsby-plugin-image').IGatsbyImageData} sanityLocation.images[].asset.gatsbyImageData
 * @property {number} sanityLocation.locationNumber
 * @property {string} sanityLocation.partsPhoneNumber
 * @property {string} sanityLocation.phoneNumber
 * @property {boolean} sanityLocation.retailLocation
 * @property {string} sanityLocation.servicePhoneNumber
 * @property {boolean} sanityLocation.showPartsPhoneNumber
 * @property {boolean} sanityLocation.showServicePhoneNumber
 * @property {string} sanityLocation.slug
 * @property {Object[]} sanityLocation.specialHours
 * @property {string} sanityLocation.specialHours[].date
 * @property {string} sanityLocation.specialHours[].hours
 * @property {string} sanityLocation.specialHours[].title
 * @property {string} sanityLocation.state
 * @property {('open'|'temporarily-closed')} sanityLocation.status
 * @property {string} sanityLocation.title
 * @property {number} sanityLocation.zip
 */

export const pageQuery = graphql`
  query locationTemplateQuery($id: String!) {
    sanityLocation(id: { eq: $id }) {
      address
      city
      descriptionHtml
      directionsLink
      geolocation {
        lat
        lng
      }
      hours {
        sunday
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
      }
      images {
        asset {
          gatsbyImageData(width: 900)
        }
      }
      locationNumber
      partsPhoneNumber
      phoneNumber
      retailLocation
      servicePhoneNumber
      showPartsPhoneNumber
      showServicePhoneNumber
      slug
      specialHours {
        date
        hours
        title
      }
      state
      status
      title
      zip
    }
    salesDepartmentImage: file(relativePath: { eq: "locations/sales-department.jpg" }) {
      ...SharpImage480
    }
    serviceDepartmentImage: file(relativePath: { eq: "locations/service-department.jpg" }) {
      ...SharpImage480
    }
    partsDepartmentImage: file(relativePath: { eq: "locations/parts-department.jpg" }) {
      ...SharpImage480
    }
    precisionAgDepartmentImage: file(
      relativePath: { eq: "locations/precision-ag-department.jpg" }
    ) {
      ...SharpImage480
    }
  }
`

export default LocationTemplate
